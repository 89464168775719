.Container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 30px;

  align-items: center;
  justify-content: center;

  margin: 10px 0;
}

.CheckContainer {
  display: flex;
  width: 50%;
  height: 30px;

  align-items: center;
  justify-content: center;

  position: relative;
}

.CircleBackground {
  display: flex;
  width: 25px;
  height: 25px;
  border-radius: 50px;
  background-color: #E8E9E9;
  align-items: center;
  justify-content: center;

  z-index: 10;
}

.RightBar {
  display: flex;
  height: 5px;
  width: 50%;

  background-color: #e8e9e9;
  position: absolute;

  right: 0px;
  top: 12px;
}

.LeftBar {
  display: flex;
  height: 5px;
  width: 50%;

  background-color: #e8e9e9;
  position: absolute;

  left: 0px;
  top: 12px;
}

.FullBar {
  display: flex;
  height: 5px;
  width: 100%;

  background-color: #e8e9e9;
  position: absolute;

  left: 0px;
  top: 12px;
}
