#moods {
  margin-bottom: 62px;
}

#moods .row {
  margin-bottom: 0px;
}

#moods .nenhum {
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;
  margin-top: 10rem;
}

#moods .nenhum h5 {
  text-align: center;
}

#moods .nenhum img {
  width: 90px;
  height: 90px;
}

#moods img {
  width: 55px;
  height: 55px;
}

#moods div.card-panel {
  padding: 10px;
  margin: 0.3rem 0 0.2rem 0;
  height: 75px;
}

#moods div.card-panel:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

#moods a.btn-add {
  width: 60px;
  height: 60px;
  bottom: 70px;
  right: 10px;
  line-height: 75px;
  position: fixed;
}

#moods p.data {
  color: #666;
}

#moods small {
  color: #666;
}

#moods a {
  color: inherit;
}

#moods strong {
  text-transform: uppercase;
}

#moods span.edit {
  background: #e8e8e8;
  height: 64px;
  width: 160px;
  right: 11px;
  padding: 0 5px 0 10px;
  font-weight: 200;
  margin-top: 10px;
  position: absolute;
  display: flex;
  align-items: center;
  border-bottom-left-radius: 50px;
  border-top-left-radius: 50px;
}

#moods span.edit .svg {
  margin-left: 10px;
}

#edit {
  margin-bottom: 62px;
  height: 45em;
  display: flex;
  justify-content: center;

}

#edit.box-diario {
  text-align: center;
  width: 100%;
}

#edit a {
  color: inherit;
}

#edit .back {
  top: 100px;
  position: absolute;
  left: 350px;
}

#edit div.mood-info {
  width: 100%;
  margin-top: 80px;
}

#edit img {
  width: 48px;
  height: 48px;
  padding: 3px;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
}

#edit img.img-diary {
  width: 58px;
  height: 58px;
  padding: 1px;
  margin: 2px 5px;
  filter: grayscale(0%);
  -webkit-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  -ms-filter: grayscale(0%);
  -o-filter: grayscale(0%);
}

#edit .form-emojis {
  margin-top: 60px !important;
  min-height: 160px;
}

#edit img:hover {
  cursor: pointer;
  filter: grayscale(0%);
  -webkit-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  -ms-filter: grayscale(0%);
  -o-filter: grayscale(0%);
}

#edit img.active {
  filter: grayscale(0%);
  -webkit-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  -ms-filter: grayscale(0%);
  -o-filter: grayscale(0%);
}

.MuiInput-underline:before {
  border-bottom: 1px solid #c5c5c5 !important;
}

.psico-textarea {
  margin: 0 !important;
  padding: 0 !important;
  background-color: #efefef !important;
}

.psico-textarea textarea {
  font-size: 12px !important;
}

#edit span.mood {
  display: block;
  font-size: 12px;
  font-weight: 500;
  color: #767575;
  margin-top: 10px;
  text-transform: uppercase;
}

#edit textarea.desc {
  height: 100px;
  border-radius: 10px;
  padding: 8px;
  background: #e8e8e8;
  border: none;
  color: #1c1c1c;
  opacity: 1;
}

#edit textarea::placeholder {
  color: #1c1c1c;
}

#edit button {
  margin-top: 20px;
  width: 250px;
  border-radius: 20px;
  height: 42px;
}

.psico-container .psico-image-emoji {
  margin-left: 6px;
  height: 38px;
}

@media screen and (min-width: 320px) {
  #edit div.mood-info {
    margin-top: 0;
  }

  #edit .back {
    top: 60px;
    position: absolute;
    left: 5px;
  }

  #edit .form-emojis {
    margin-top: 60px !important;
    min-height: 140px;
  }

  .offset-steps {
    margin-left: 6% !important;
  }
}

@media screen and (max-width: 360px) {
  #edit {
    height: 35em;
  }

  #edit img {
    width: 42px !important;
    height: 42px !important;
  }

  #edit .form-emojis {
    margin-top: 60px !important;
    min-height: 200px;
  }

  .offset-steps {
    margin-left: 6% !important;
  }

  .psico-container .psico-image-emoji {
    height: 30px
  }
}

@media screen and (max-width: 320px) {
  #edit {
    height: 35em;
  }

  #edit .form-emojis {
    margin-top: 60px !important;
    min-height: 140px;
  }

  #edit img {
    width: 42px !important;
    height: 42px !important;
  }
}

@media screen and (min-width: 992px) {
  #edit .back {
    top: 70px;
    position: absolute;
    left: 5px;
  }

  .offset-steps {
    margin-left: 28% !important;
  }
}

@media (max-width: 945px) {
  section.lab h3.card-title {
    padding: 5px;
    font-size: 16px;
  }

  .offset-steps {
    margin-left: 6% !important;
  }
}

@media (max-width: 360px) {
  #edit img.img-diary {
    width: 50px;
    height: 50px;
  }
}

a.portfolio-link {
  margin: 0 auto;
  display: block;
  text-align: center;
}

#consultation {
  color: #666;
  margin-bottom: 62px;
}

#consultation .row {
  margin-bottom: 0px;
}

#consultation img {
  width: 100px;
  height: 100px;
}

#consultation .avatar img {
  height: 110px !important;
  width: 110px !important;
}

#consultation small {
  font-size: 13px;
  color: #666;
}

#consultation a {
  color: inherit;
}

#consultation .td-btn button {
  margin-top: 2px;
  width: 250px;
  border-radius: 25px;
}

#consultation .td-btn small {
  font-size: 10px;
}

#consultation table tr td button {
  font-size: 11px;
  margin-top: 0px;
}

#consultation div.card-panel {
  padding: 10px;
  margin: 0.3rem 0 0.2rem 0;
  height: 83px;
}

#consultation div.card-panel:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

#consultation div.date {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 19px 10px 0px 10px;
}

#consultation #reagendamento {
  color: #fff;
}

#consultation div.date.paid {
  color: #4caf50;
}

#consultation div.date.unpaid {
  color: #f44336;
}

#consultation div.date span.day {
  font-size: 40px;
  line-height: 0px;
}

#consultation div.date span.month {
  font-size: 25px;
  margin-top: 10px;
  text-transform: uppercase;
}

#consultation div.dates span.hours {
  font-size: 25px;
}

#consultation div.dates span.paid.hours {
  color: #4caf50;
}

#consultation div.dates span.unpaid.hours {
  color: #f44336;
}

#consultation div.dates label.week span.badge {
  font-size: 11px;
  float: left;
  margin-left: 0;
}

#consultation .nenhuma {
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;
  margin-top: 10rem;
}

#edit-consultation {
  margin: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#edit-consultation .badge {
  font-size: 11px;
  margin: 20px 0;
  padding: 1px 10px;
  text-transform: uppercase;
}

#edit-consultation strong h1 {
  font-size: 48px;
  font-weight: 0;
  margin: 0;
  text-align: center;
  text-transform: uppercase;
}

span.psico-simple-text {
  font-size: 15px;
  margin: 10px 0;
  padding: 0 12px;
}

.input-date-time input[type="text"] {
  color: #5f5f5f;
  box-shadow: none !important;
}

.psico-diary-link-item {
  display: block;
  width: 100%;
}

.psico-label-icon {
  color: #5f5f5f;
  display: block;
  text-align: center;
}

.psico-input-label-span {
  margin-left: 5px;
  position: relative;
  top: -2px;
}

.psico-label-input input {
  border: 0 none !important;
  font-size: 12px !important;
  height: 0.8rem !important;
  text-align: center !important;
}

.psico-label-input-register input {
  border: 0 none !important;
  font-size: 12px !important;
  height: 1.75rem !important;
  margin-bottom: 0 !important;
  padding-bottom: 4px !important;
  margin-top: 0px !important;
  padding-left: 5px !important;
}

.psico-label-input-register .input-date-time {
  width: 100% !important;
  margin-top: 4px !important;
  box-shadow: 0 none !important;
}

.psico-label-icon.label-register {
  font-size: 10px !important;
  color: #b3b3b6 !important;
  text-align: left;
}

.mb0 {
  margin-bottom: 0 !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.psico-label-input-register .MuiInput-underline:before {
  border-bottom: 1px solid #9e9e9e !important;
}



#edit-consultation label {
  font-size: 15px;
}

#edit-consultation label.psico-consultation-date {
  color: #767575;
  margin-top: 5px;
}

#edit-consultation strong h2 {
  font-size: 30px;
  font-weight: 0;
  margin: 0;
  text-transform: uppercase;
}

#edit-consultation #reagendar {
  width: 320px;
}

#edit-consultation #reagendar.none {
  display: none;
}

#edit-consultation .horarios {
  margin: 10px 0;
}

#edit.box-diario {
  height: inherit !important;
  margin-bottom: 0 !important;
}

.psico-button {
  border-radius: 20px !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  margin: 20px 0 !important;
  width: 180px !important;
  text-transform: capitalize !important;
  height: 40px !important;
}

.psico-button.btn-wizard span {
  font-size: 14px !important;
}

.box-diario .psico-button.btn-wizard {
  width: 80% !important;
}

.psico-button-actions .psico-button.psico-button-delete {
  margin: 0 !important;
}

.psico-link-cancel {
  margin-bottom: 60px !important;
  margin-top: 10px !important;
  padding: 0 !important;
}

.psico-link-cancel span {
  font-size: 12px !important;
}

.psico-list-item-diary .psico-title-list-item,
.psico-list-item-diary .psico-title-list-emoji {
  display: block;
  margin: 2px 0 2px 4px;
}

.psico-list-item-diary .psico-title-list-emoji {
  margin: 2px 0 3px 5px;
  text-transform: uppercase;
  font-weight: 500;
}

.psico-list-item-diary span.badge {
  line-height: 18px;
  height: 18px;
}

.psico-list-item-diary span.badge.new.success {
  background-color: #DEF7E4;
  border-radius: 10px;
  /* color: #28A745; */
  font-size: 0.7rem;
  font-weight: 500;
}

.psico-list-item-diary span.badge.new.normal {
  background-color: #E8E9E9;
  border-radius: 10px;
  color: #7D8C94;
  font-size: 0.7rem;
  font-weight: 500;
}

.psico-list-item-diary span.badge.new.normal::after,
.psico-list-item-diary span.badge.new.success::after {
  content: '';
}

.psico-list-item-activities .psico-list-item-information {
  float: left;
  margin: 2px 6px 2px 4px;
  font-size: 10px;
  text-transform: uppercase;
}

.box-history .MuiBox-root {
  padding: 0 !important;
}

.box-history .psico-history-date {
  padding: 8px 13px;
  background-color: #e5e5e5;
  font-weight: 600;
  font-size: 12px;
  color: #7c7c7c;
  border-bottom: 1px solid #d7d7d7;
  border-top: 1px solid #d7d7d7;
}

.box-history .psico-history .MuiListItem-gutters {
  padding: 4px 8px !important;
}

.p0 {
  padding: 0 !important;
}

#edit-consultation #reagendamento {
  display: none;
}

#edit-consultation span.badge {
  font-weight: 500;
}

#content {
  text-align: center;
}

section.timeline-outer {
  width: 100%;
  margin: 0 auto;
}

h1.header {
  font-size: 50px;
  line-height: 70px;
}

ul:not(.browser-default) {
  padding-left: inherit;
}

.mb0 {
  margin-bottom: 0 !important
}

.mt0 {
  margin-top: 0 !important
}

.fade-in {
  transition: opacity 1s ease;
}

.fade-out {
  opacity: 0;
  transition: opacity 1s ease;
}

/* Timeline */

.timeline {
  border-left: 8px solid #d2d0d0;
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  color: #333;
  margin: 0 auto;
  letter-spacing: 0.5px;
  position: relative;
  line-height: 1.4em;
  padding: 20px;
  list-style: none;
  text-align: left;
}

.timeline h1,
.timeline h2,
.timeline h3 {
  font-size: 1.4em;
}

.timeline h3 {
  font-weight: 600;
}

.timeline .event {
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 15px;
  margin-bottom: 20px;
  position: relative;
}

.timeline .event:last-of-type {
  padding-bottom: 0;
  margin-bottom: 0;
  border: none;
}

.timeline .event:before,
.timeline .event:after {
  position: absolute;
  display: block;
  top: 0;
}

.psico-abas div div {
  padding: 5px 0px 5px 5px !important;
}

#diary {
  margin-bottom: 60px;
}

@media only screen and (min-width: 993px) {
  .container {
    width: 60%;
  }

  .MuiTab-root {
    min-height: 62px !important;
  }
}

.timeline .event:before {
  left: -177.5px;
  color: #666;
  content: attr(data-date);
  text-align: right;
  font-size: 10px;
  min-width: 120px;
  float: left;
  width: 120px;
}

.timeline .event:after {
  box-shadow: 0 0 0 8px #d2d0d0;
  left: -30px;
  background: #756e6e;
  border-radius: 50%;
  height: 11px;
  width: 11px;
  content: '';
  top: 5px;
}

.timeline img.moods {
  width: 25px;
  height: 25px;
  margin: 0 10px;
  position: absolute;
  left: -47px;
  top: -2px;
  z-index: 2;
}

.timeline p {
  font-size: 12.5px;
}

@media (max-width: 945px) {
  .timeline .event::before {
    left: 0.5px;
    top: 20px;
    text-align: left;
    min-width: 0;
    font-size: 11px;
    width: 100%;
  }

  .timeline h3 {
    font-size: 16px;
  }

  .timeline p {
    padding-top: 20px;
  }

  section.lab h3.card-title {
    padding: 5px;
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .timeline .event::before {
    left: 0.5px;
    top: 20px;
    min-width: 0;
    font-size: 11px;
  }

  .timeline .event:nth-child(1)::before,
  .timeline .event:nth-child(3)::before,
  .timeline .event:nth-child(5)::before {
    top: 20px;
  }

  .timeline h3 {
    font-size: 16px;
  }

  .timeline p {
    padding-top: 20px;
  }
}

a.portfolio-link {
  margin: 0 auto;
  display: block;
  text-align: center;
}

.psico-list-icon {
  height: 20px !important;
  min-height: 20px !important;
  width: 20px !important;
  margin: 4px 0 0 4px !important;
}

.psico-list-icon * {
  font-size: 12px !important;
}

.psico-list-icon.icon-information,
.psico-list-icon.icon-information:hover {
  color: #fff;
  background-color: rgb(25, 118, 210);
}

.psico-list-icon.icon-success,
.psico-list-icon.icon-success:hover {
  color: #fff;
  background-color: rgb(16, 173, 71);
}

.psico-list-icon.icon-danger,
.psico-list-icon.icon-danger:hover {
  color: #fff;
  background-color: rgb(195, 15, 32);
}

.psico-list-icon.icon-primary,
.psico-list-icon.icon-primary:hover {
  color: #fff;
  background-color: rgb(149, 16, 204);
}

.psico-list-icon.icon-dark,
.psico-list-icon.icon-dark:hover {
  color: #fff;
  background-color: #000;
}

.psico-list-icon.icon-info,
.psico-list-icon.icon-info:hover {
  color: #fff;
  background-color: #1976d2;
}

.psico-list-info-icon.icon-adjustment {
  top: 2px;
  position: relative;
}

.psico-list-info-icon {
  font-size: 13px !important;
  margin-right: 4px;
}

.psico-list-item-information {
  font-size: 11px;
  color: #333;
}

.psico-list-break-information {
  display: block;
  float: left;
  position: relative;
  margin: 4px 10px 2px 0;
}

.psico-avatar-list-item {
  font-weight: bold;
  font-size: 0.8rem !important;
  line-height: 14px;
  text-align: center;
  text-transform: uppercase;
}

.psico-title-list-item {
  color: #585453;
  font-size: 13px;
  font-weight: 500;
  line-height: 13px;
}

.psico-list-info-text {
  color: #7b7979;
  font-size: 11px !important;
  margin-top: 4px !important;
  display: block !important;
}

.MuiListItemText-secondary {
  margin: 2px 0 !important;
}

#box-config .row {
  margin: 0;
}

#box-config .row .col {
  padding: 0;
}

#box-config .row .collection {
  border: 0 none;
  border-bottom: 1px solid #e0e0e0;
  margin: 0;
}

#box-config .row .collection .collection-item {
  float: left;
  line-height: inherit;
  padding: 12px 10px;
  width: 100%;
}

#box-config .row .collection .collection-item:hover {
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer;
}

#box-config .row .secondary-content {
  color: #595555;
  margin-top: 6px;
}

#box-config .row .col-small {
  margin-top: -5px;
}

#box-config .row small {
  color: #787878;
}

#box-change-password .row button {
  border-radius: 25px;
  margin-top: 25px;
}

.psico-fab {
  bottom: 80px !important;
  cursor: pointer !important;
  height: 50px !important;
  right: 20px !important;
  line-height: 65px !important;
  position: fixed !important;
  width: 50px !important;
}

#box-change-password .row button.psico-fab {
  border-radius: 25px;
  margin-top: -20px;
  margin-left: -3px;
}

#box-change-password .row .msg-form {
  font-size: 13px;
  margin-bottom: 10px;
  margin-top: 30px;
}

#box-change-password p {
  font-size: 12px;
}

#help {
  height: 30em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  line-height: 1px;
  padding: 0 10px;
}

#help.box-download-app {
  line-height: 24px !important;
  padding: 0 28px;
}

#help img {
  width: 100px;
  height: 100px;
}

#help small {
  padding: 10px;
  text-align: center;
  line-height: 22px;
  font-size: 13px;
  color: #666;
}

#help small a {
  font-size: 13px;
}

#help h5 {
  text-align: center;
  font-size: 20px;
  margin: 20px 0 10px;
}

#found {
  height: 30em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  line-height: 1px;
}

#found img {
  width: 100px;
  height: 100px;
}

#found small {
  padding: 10px;
  text-align: center;
  line-height: 22px;
  font-size: 13px;
  color: #666;
}

#found small a {
  font-size: 12px;
  text-transform: uppercase;
}

#found h5 {
  text-align: center;
  font-size: 20px;
  margin: 20px 0 10px;
}

.psico-box-select label {
  left: 0 !important;
  font-size: 12px !important;
}

.psico-box-select div {
  font-size: 12px !important;
  color: #777676 !important;
}

.input-field label {
  font-size: 12px;
}

.input-field input[type='text'] {
  height: 2.6rem;
  font-size: 12px;
}
.input-field input[type='number'] {
  height: 2.6rem;
  font-size: 12px;
}

.psico-box-select .MuiFormControl-root {
  width: 100% !important;
}

.psico-box-select .MuiInputBase-input {
  font-size: 11px !important;
  padding: 5px 0 4px;
}

.psico-number-step {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background: #8664a7;
  margin: 0 auto 10px auto;
  border: 1px solid #fff;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  display: inline-block;
  margin-right: 2px;
  line-height: 28px;
}

.psico-title-step {
  color: #7c7c7c;
  font-size: 18px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.43;
  letter-spacing: 0.01071em;
}

.psico-container .psico-label-icon .psico-input-label-span,
.psico-diario .psico-label-icon .psico-input-label-span {
  font-size: 12px !important;
}

.m0 {
  margin: 0 !important;
}

.psico-input-text input[type="text"],
.psico-input-text input[type=text]:not(.browser-default):focus:not([readonly]) {
  border-bottom: 0 none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  padding: 6px 12px 0;
}

.icon-item,
.icon-selected,
.icon-secondary {
  color: rgba(0, 0, 0, 0.54);
  margin: 0 1px;
  display: inline-block;
  overflow: hidden;
  font-size: 10px;
  text-align: center;
  text-overflow: ellipsis
}

.emoji-item {
  display: inline-block;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
}

.icon-description {
  color: #767575;
  display: block;
  font-size: 9px !important;
  font-weight: 500;
  overflow: hidden;
  position: relative;
  text-align: center;
  text-overflow: ellipsis;
  text-transform: uppercase;
  top: -15px;
  width: 60px;
  height: 22px;
  white-space: nowrap;
}

.psico-container .icon-description {
  top: 0;
  height: inherit;
}

.emoji-item .icon-description {
  width: 100%;

  font-weight: 500;
}

.box-emoji-modal .emoji-item .icon-description,
.box-activities-modal .icon-item .icon-description,
.box-activities-modal .icon-selected .icon-description {
  top: 0;
}

.icon-item .psico-icon {
  color: #5f5f5f;
  cursor: pointer;
  margin: 4px 0px;
  padding: 8px;
  font-size: 22px;
  box-sizing: content-box;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #fff;
  border-radius: 50%;
}

.icon-selected .psico-icon {
  color: #fff;
  cursor: pointer;
  margin: 4px 0px;
  padding: 8px;
  font-size: 22px;
  box-sizing: content-box;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
  background-color: #e33371;
  border-radius: 50%;
}

.icon-secondary .psico-icon {
  color: #fff;
  cursor: pointer;
  margin: 4px 0px;
  padding: 8px;
  font-size: 22px;
  box-sizing: content-box;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
  background-color: #2196f3;
  border-radius: 50%;
}

.psico-title-icon-back {
  color: #7c7c7c;
  position: absolute;
  top: 132px;
  left: 10px;
  cursor: pointer;
  font-size: 1.8rem !important;
  /* box-sizing: content-box;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
  background-color: #8664a7;
  border-radius: 50%; */
}

.MuiTabs-flexContainer {
  padding: 1px;
}

footer .material-icons {
  width: 5.1rem !important
}

footer .active {
  color: #3f3f3f !important
}

.box-psico-progress {
  position: relative;
  text-align: center;
  margin: 20px 0;
}

.spinner-green, .spinner-purple-only {
  border-color: #8664a7
}

.circle-clipper .circle {
  border-width: 4px
}

.ml-10 {
  margin-left: 10px;
}

button.MuiFab-primary,
.MuiAppBar-colorPrimary,
.purple {
  background-color: #8664a7  !important;
}

.white {
  background-color: #fff !important;
}

.MuiFormLabel-root.Mui-focused {
  color: #8664a7 !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.wizard-container {
  z-index: 1;
}

.box-diario .MuiCheckbox-root {
  padding: 4px 8px !important;
}

.box-diario .MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-size: 0.9rem !important;
}

.progressbar li {
  float: left;
  position: relative;
  text-align: center;
  width: 20%
}

.progressbar li:before {
  content: '1';
  height: 30px;
  width: 30px
}

.progressbar li:before {
  content: '1';
  width: 21px;
  height: 21px;
  border: 2px solid #bebebe;
  display: block;
  font-weight: bold;
  margin: 6px auto 10px auto;
  border-radius: 50%;
  line-height: 18px;
  background: #fff;
  color: #bebebe;
  text-align: center;
  font-size: 12px;
}

.progressbar {
  counter-reset: step;
}

.progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 21px;
  height: 21px;
  border: 2px solid #bebebe;
  display: block;
  margin: 6px auto 10px auto;
  font-weight: bold;
  border-radius: 50%;
  line-height: 18px;
  background: white;
  color: #bebebe;
  text-align: center;
  font-size: 12px
}

.progressbar li:after {
  content: '';
  background: #979797;
  position: absolute;
  width: 100%;
  height: 3px;
  top: 15px;
  left: -50%;
  z-index: -1;
}

.progressbar li:first-child:after {
  content: none;
}

.progressbar li.active:after,
.progressbar li.active:before {
  color: #fff;
  border-color: #8664a7;
  background: #8664a7
}

.progressbar li:first-child:before {
  color: #fff;
  border-color: #8664a7;
  background: #8664a7;
}

.psico-container {
  padding: 15px 10px;
  margin-bottom: 40px;
  width: 99.5%;
  display: block;
  overflow: hidden;
}

.psico-container .step {
  padding: 5px 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.psico-container .v-stepper {
  position: relative;
  margin-top: 15px;
}

.psico-container .psico-chip {
  margin: 4px 2px 4px 0;
}

.psico-container .step .circle {
  background-color: white;
  border: 3px solid gray;
  border-radius: 100%;
  width: 24px;
  height: 24px;
  display: inline-block;
  margin-left: 1px;
}

.psico-container .step .line {
  top: 25px;
  left: 11px;
  height: 100%;
  position: absolute;
  border-left: 3px solid gray;
}

.psico-container .step.normal .circle,
.psico-container .step.completed .circle {
  visibility: visible;
  background-color: #e1e2e2; /* rgb(6,150,215) */
  border-color: #e1e2e2;
}

.psico-container .step.normal .line,
.psico-container .step.completed .line {
  border-left: 5px solid #e1e2e2;
}

.psico-container .step.active .circle {
  visibility: visible;
  border-color: #e1e2e2;
}

.psico-container .step.empty .circle {
  visibility: hidden;
}

.psico-container .step.empty .line {
  top: 0;
  height: 150%;
}

.psico-container .step:last-child .line {
  border-left: 3px solid white;
  z-index: -1;
}

.psico-container .content {
  border-bottom: 1px solid #CCD1D3;
  margin-right: 20px;
  padding-top: 8px;
  padding-bottom: 18px;
  display: inline-block;
  width: 100%;
}

.psico-container .step.normal .step-icon {
  color: #fff;
  font-size: 16px;
  font-weight: bold;
}

.psico-container .step.completed .step-icon {
  color: #28A745;
  font-size: 18px;
  font-weight: bolder;
}

.psico-container .span-percent {
  color: #677176;
  display: block;
  font-size: 12px;
  position: relative;
  top: -6px;
  width: 100%;
}

.psico-container .span-text {
  color: #677176;
  display: block;
  font-size: 12px;
  width: 100%;
}

.psico-container .psico-desc-emoji {
  display: block;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
}

.psico-container .step-title {
  color: #7c7c7c;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  margin: 0 0 14px 0;
  position: relative;
  top: -2px
}

.psico-container .psico-description {
  font-size: 12px;
}

.psico-container .psico-button-edit {
  color: #7c7c7c;
  cursor: pointer;
  font-size: 10px;
  padding: 0 !important;
  position: relative;
  text-decoration: underline;
  top: -5px;
}

.psico-container .psico-button-edit:hover,
.psico-container .psico-button-edit:focus,
.psico-container .psico-button-edit:active {
  background-color: #fff
}

.mb-0 {
  margin-bottom: 0 !important
}

.psico-qr-code {
  width: 100%;
  height: auto;
  min-width: 150px;
  min-height: 150px
}

.title-download-app {
  color: black;
  font-size: 20px;
  margin-bottom: 25px;
}

.msg-download-app {
  font-size: 15px;
  margin-bottom: 18px;
  text-align: center;
}

.nome-profissional {
  color: black;
  font-size: 18px;
  text-align: left;
  margin: 0;
  padding: 0;
}
