.Button {
  width: 140px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #8664a7;
  outline: none;
  margin: 13px;
  cursor: pointer;
}

.Próximo,
.Salvar {
  background-color: #8664a7;
  color: #f5f5f5;
}

.Próximo:active,
.Salvar:active {
  background-color: #9f84b9;
  transform: scale(0.95);
}

.Próximo:focus,
.Salvar:focus {
  background-color: #8664a7;
}

.Anterior {
  background-color: #f5f5f5;
  color: #8664a7;
}

.Anterior:active {
  background-color: #e6e6e6;
  transform: scale(0.95);
}

.Anterior:focus {
  background-color: #f5f5f5;
}
