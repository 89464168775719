.containerDropDown {
  margin: 0 4px;
  width: 100%;
}

.middle {
  width: 47%;
}

.small {
  width: 27%;
}

*#DropDownContainer {
  position: relative;
  width: 100%;
  margin-top: 18px;
  margin: 8px 0px;
}

*#titleBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 32px;
  position: relative;
}

.titleContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 5px;
}

.titleContainer p {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #3d3d3d;
}

.subTitle {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  /* line-height: 24px; */
  color: #4c5153;
}

.smallText {
  font-size: 13px;
}

.selectBox {
  width: 100%;
  border-radius: 2px;
  background-color: #ffffff;
  padding: 0px 8px;
}

.closed {
  border: 1px solid #ccd1d3;
}

.opend {
  border: 1px solid #8664a7;
}

.OpendBox {
  width: 100%;
  max-height: 124px;
  border-radius: 2px;
  background-color: #ffffff;
  padding: 0px 8px;
  filter: drop-shadow(0px 2px 16px rgba(76, 81, 83, 0.24));
  position: absolute;
  z-index: 1;
  overflow-y: scroll;
}

.OpendBox::-webkit-scrollbar {
  width: 0.5rem;
}

.OpendBox::-webkit-scrollbar-track {
  background: #ffffff;
}

.OpendBox::-webkit-scrollbar-thumb {
  background: #888;
}

.OpendBox p {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #7d8c94;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.OpendBox p:hover {
  cursor: pointer;
}

.searchInputContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #9e9e9e;
}

.Input {
  border: none !important;
  margin: 0 !important;
  margin-left: 8px !important;
  width: 90% !important;
  box-shadow: none;
}

.input:focus:not([readonly]) {
  outline: none !important;
  box-shadow: none !important;
}
